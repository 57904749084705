<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <img
          class="poster"
          src="/images/poster/ONLINE-WELLBEING-ASSESSMENT.png"
        />
        <button
          type="button"
          v-if="!show"
          @click="onGeneratePdf"
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          GENERATE PDF
        </button>
        <button
          type="button"
          disabled
          v-else
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          <b-spinner large></b-spinner>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: [],
      show: false,
      errorImg: false,
    };
  },
  methods: {
    onGeneratePdf() {
      window.open(
        `https://vt6vaxywlb.execute-api.eu-west-2.amazonaws.com/pdf?type=ONLINEWELLBEINGASSESSMENT`,
        "_blank"
      );
    },
  },
};
</script>
