<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <input
          v-model="url"
          type="text"
          class="poster form-control"
          placeholder="URL"
          style="
            left: 395px;
            top: 1095px;
            width: 324px;
            height: 30px;
            font-size: 1.8em;
          "
        />
        <input
          v-model="code"
          type="text"
          class="poster form-control"
          placeholder="The event code"
          style="
            left: 535px;
            top: 1135px;
            width: 184px;
            height: 30px;
            font-size: 1.8em;
          "
        />

        <img
          class="poster"
          src="/images/poster/POSITIVE-LIFESTYLE-CHANGE.png"
        />
        <button
          type="button"
          v-if="!show"
          @click="onGeneratePdf"
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          GENERATE PDF
        </button>
        <button
          type="button"
          disabled
          v-else
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          <b-spinner large></b-spinner>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: "HPTestCode1",
      url: "www.healthyperformance.co.uk",
      file: [],
      show: false,
      errorImg: false,
    };
  },
  methods: {
    onGeneratePdf() {
      window.open(
        `https://vt6vaxywlb.execute-api.eu-west-2.amazonaws.com/pdf?code=${encodeURIComponent(
          this.code
        )}&url=${encodeURIComponent(this.url)}&type=POSITIVELIFESTYLECHANGE`,
        "_blank"
      );
    },
  },
};
</script>
