<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import "@aws-amplify/ui-vue";
import DefaultLayout from "./layouts/DefaultLayout";
import FullPageLayout from "./layouts/FullPageLayout";

export default {
  name: "App",
  components: {
    DefaultLayout,
    FullPageLayout,
  },
  data() {
    return {};
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
};
</script>
