<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <input
          v-model="code"
          type="text"
          class="poster form-control"
          placeholder="Enter code"
          style="
            left: 417px;
            top: 560px;
            width: 189px;
            height: 50px;
            font-size: 1.8em;
          "
        />

        <img class="poster" src="/images/poster/DFV-REMINDER.png" />

        <button
          type="button"
          v-if="!show"
          @click="onGeneratePdf"
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          GENERATE PDF
        </button>
        <button
          type="button"
          disabled
          v-else
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          <b-spinner large></b-spinner>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: "",
      file: [],
      show: false,
      errorImg: false,
    };
  },
  methods: {
    onGeneratePdf() {
      this.show = true;
      window.open(
        `https://vt6vaxywlb.execute-api.eu-west-2.amazonaws.com/pdf?code=${encodeURIComponent(
          this.code
        )}&type=DFVREMINDER`,
        "_blank"
      );
      this.show = false;
    },
  },
};
</script>
<style scoped>
.err {
  width: 100%;
  margin-bottom: 0.25rem;
  font-size: 150%;
  color: #dc3545;
}
</style>
