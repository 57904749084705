<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-center">
          <input
            v-model="location"
            type="text"
            class="poster form-control"
            placeholder="Location"
            style="
              top: 127px;
              width: 300px;
              height: 30px !important;
              font-size: 2em;
            "
          />

          <textarea
            class="poster form-control"
            v-model="dates"
            rows="3"
            style="top: 170px; width: 800px; height: 105px; font-size: 1.86em"
          ></textarea>

          <input
            v-model="dropin"
            type="text"
            class="poster form-control"
            placeholder="Blood drop in"
            style="top: 285px; width: 800px; height: 30px; font-size: 1.8em"
          />
        </div>

        <input
          v-model="room"
          type="text"
          class="poster form-control"
          placeholder="The room"
          style="
            left: 200px;
            top: 327px;
            width: 715px;
            height: 30px;
            font-size: 1.8em;
            text-align: left;
          "
        />

        <input
          v-model="code"
          type="text"
          class="poster form-control"
          placeholder="The event code"
          style="
            left: 307px;
            top: 1132px;
            width: 184px;
            height: 30px;
            font-size: 1.8em;
          "
        />

        <img
          class="poster"
          src="/images/poster/EHA45-PSA-THYROID-LIVER-NR.png"
        />
        <div class="bg-light mt-3 logo-upload-poster">
          <VueFileAgent
            ref="fileRef"
            :multiple="false"
            :deletable="false"
            :meta="false"
            :compact="true"
            :accept="'image/*'"
            :helpText="'Drag an image file here'"
            :errorText="{
              type: 'Please select an image',
            }"
            v-model="file"
          >
            <template v-slot:before-outer> </template>
            <template v-slot:after-inner>
              <span title="after-inner" class="btn btn-link btn-sm btn-block"
                >Select image file</span
              >
            </template>
          </VueFileAgent>
          <div class="err" v-if="errorImg">
            Image not attached, please try again
          </div>
        </div>

        <button
          type="button"
          v-if="!show"
          @click="onGeneratePdf"
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          GENERATE PDF
        </button>
        <button
          type="button"
          disabled
          v-else
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          <b-spinner large></b-spinner>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
export default {
  data() {
    return {
      location: "Southam",
      dates:
        "Monday 27th September, 09:00 - 17:00 \nTuesday 28th September, 11:00 - 19:00\nWednesday 29th September, 09:00 - 17:00",
      code: "HPTestCode1",
      room: "Main conference room",
      dropin: "Blood drop in 09:00 - 10:00 (To reduce fasting period)",
      file: [],
      show: false,
      errorImg: false,
    };
  },
  methods: {
    onGeneratePdf() {
      if (!this.file[0]) {
        this.errorImg = true;
        return false;
      }
      this.show = true;
      fetch(
        `https://vt6vaxywlb.execute-api.eu-west-2.amazonaws.com/uploadS3?fileName=${encodeURIComponent(
          this.file[0].file.name
        )}&contentType=${encodeURIComponent(this.file[0].file.type)}`
      )
        // Decode the JSON response
        .then((response) => response.json())
        .then((response) => {
          // Now that we have our pre-signed URL, let's upload our file to this URL:
          fetch(response.url, {
            // We must use the PUT verb because this is a S3.putObject pre-signed URL
            method: "PUT",
            headers: {
              // This must be the same content-type used to create the pre-signed URL
              "Content-Type": this.file[0].file.type,
            },
            // We pass the form field object, `fetch()` will put the file content in there
            body: this.file[0].file,
          }).then((response) => {
            this.show = false;
            window.open(
              `https://vt6vaxywlb.execute-api.eu-west-2.amazonaws.com/pdf?location=${encodeURIComponent(
                this.location
              )}&dates=${encodeURIComponent(
                this.dates
              )}&room=${encodeURIComponent(
                this.room
              )}&code=${encodeURIComponent(
                this.code
              )}&room=${encodeURIComponent(
                this.room
              )}&image=${encodeURIComponent(
                this.file[0].file.name
              )}&dropin=${encodeURIComponent(
                this.dropin
              )}&type=EHA45PSATHYROIDLIVERNR`,
              "_blank"
            );
          });
        });
      // const location = 'North Hykeham';
      // const days = 'Every Tuesday, Wednesday & Thursday';
      // const date = 'from 15 June - 22 July';
      // const code = 'NHSSurrey';
    },
  },
};
</script>
<style scoped>
.err {
  width: 100%;
  margin-bottom: 0.25rem;
  font-size: 150%;
  color: #dc3545;
}
</style>
