import Vue from "vue";
import Vuex from "vuex";
import { Auth } from "aws-amplify";

Vue.use(Vuex);

export default new Vuex.Store({
  state: { user: null },
  mutations: {
    setUser(state, payload) {
      console.log('SET USER!');
      state.user = payload;
    }
  },
  actions: {
    async logout({ commit }) {
      commit("setUser", null);
      return await Auth.signOut();
    },
    async login({ commit }, { username, password }) {
      try {
        await Auth.signIn({
          username,
          password
        });

        const userInfo = await Auth.currentUserInfo();
        commit("setUser", userInfo);
        return Promise.resolve("Success");

      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
    async authAction({ commit }) {
      const userInfo = await Auth.currentUserInfo();
      commit("setUser", userInfo);
    }
  },
  getters: {
    user: (state) => state.user
  }
});
