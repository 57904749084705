import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { Auth } from "aws-amplify";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true, layout: "DefaultLayout" },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import("../views/Login.vue"),
    meta: { layout: "FullPageLayout" }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = !!await Auth.currentUserInfo();

  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
    if (requiresAuth) {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const userRoles = authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"];
      let role = to.meta.role;
      if (typeof to.meta.role !== "undefined") {
        let authorised = false;
        for (var i = 0; i < userRoles.length; i++) {
          role.forEach(function (r) {
            if (userRoles[i] === r) {
              authorised = true;
            }
          });
        }
        if (!authorised) {
          next("/login");
        }
      }
    }
    next()
  }
})

export default router;
