<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <img
          class="poster"
          src="/images/poster/MYWELLBEINGCHECK-LAUNCH-EMAIL-SIGNATURE.png"
        />
        <div
          class="bg-light mt-3 logo-upload-poster-calendar-days"
          style="top: 12px; left: 57px"
        >
          <VueFileAgent
            ref="fileRef"
            :multiple="false"
            :deletable="false"
            :meta="false"
            :compact="true"
            :accept="'image/*'"
            :helpText="'Drag an image file here'"
            :errorText="{
              type: 'Please select an image',
            }"
            v-model="file"
          >
            <template v-slot:before-outer> </template>
            <template v-slot:after-inner>
              <span title="after-inner" class="btn btn-link btn-sm btn-block"
                >Select image file</span
              >
            </template>
          </VueFileAgent>
          <div class="err" v-if="errorImg">
            Image not attached, please try again
          </div>
        </div>

        <button
          type="button"
          v-if="!show"
          @click="onGeneratePdf"
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          GENERATE EMAIL SIGNATURE
        </button>
        <button
          type="button"
          disabled
          v-else
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          <b-spinner large></b-spinner>
        </button>
      </div>
    </div>
    <img
      width="900"
      v-if="base64image"
      :src="`${this.base64image}`"
      alt="Red dot"
    />
    <canvas
      id="canvas"
      width="996px"
      height="235px"
      style="display: none"
    ></canvas>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      location: "Southam",
      dates:
        "Monday 27th September, 09:00 - 17:00 \nTuesday 28th September, 11:00 - 19:00\nWednesday 29th September, 09:00 - 17:00\nThursday 30th September, 12:00 - 20:00",
      code: "HPTestCode1",
      room: "Main conference room",
      file: [],
      show: false,
      errorImg: false,
      base64image: false,
    };
  },
  methods: {
    onGeneratePdf() {
      if (!this.file[0]) {
        this.errorImg = true;
        return false;
      }
      this.show = true;
      const image = window.URL.createObjectURL(this.file[0].file);

      const canvas = document.getElementById("canvas");
      const ctx = canvas.getContext("2d");

      // Mario variables
      const BACKGROUND_WIDTH = 996;
      const BACKGROUND_HEIGHT = 235;

      const mario = new Image();
      mario.src = "/images/poster/MYWELLBEINGCHECK-LAUNCH-EMAIL-SIGNATURE.png";
      mario.onload = () => {
        ctx.drawImage(
          // Image
          mario,
          // Selection
          0, // sx
          0, // sy
          BACKGROUND_WIDTH, // sWidth
          BACKGROUND_HEIGHT // sHeight
        );
      };

      const NEW_WIDTH = 230;
      let LOGO_WIDTH = this.file[0].dimensions.width;
      let LOGO_HEIGHT = this.file[0].dimensions.height;
      if (LOGO_WIDTH > NEW_WIDTH) {
        LOGO_HEIGHT = (LOGO_HEIGHT / LOGO_WIDTH) * NEW_WIDTH;
        LOGO_WIDTH = NEW_WIDTH;
      }

      const logo = new Image();
      logo.src = image;
      logo.onload = () => {
        ctx.drawImage(
          logo,
          (NEW_WIDTH - LOGO_WIDTH + 10) / 2,
          (NEW_WIDTH - LOGO_HEIGHT) / 2,
          LOGO_WIDTH,
          LOGO_HEIGHT
        );
        canvas.toBlob(function (blob) {
          saveAs(blob, "mywellbeingcheck-signature");
        });
      };

      this.show = false;
      this.file = [];
    },
  },
};
</script>
<style scoped>
.err {
  width: 100%;
  margin-left: 3px;
  margin-bottom: 0.25rem;
  font-size: 70%;
  color: #dc3545;
}
</style>
