<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-center">
          <input
            v-model="location"
            type="text"
            class="poster form-control"
            placeholder="Location"
            style="
              top: 221px;
              width: 300px;
              height: 30px !important;
              font-size: 2em;
            "
          />

          <textarea
            class="poster form-control"
            v-model="dates"
            rows="3"
            style="top: 252px; width: 800px; height: 65px; font-size: 1.86em"
          ></textarea>
        </div>

        <input
          v-model="room"
          type="text"
          class="poster form-control"
          placeholder="The room"
          style="
            left: 200px;
            top: 327px;
            width: 715px;
            height: 30px;
            font-size: 1.8em;
            text-align: left;
          "
        />

        <input
          v-model="code"
          type="text"
          class="poster form-control"
          placeholder="The event code"
          style="
            left: 307px;
            top: 1078px;
            width: 184px;
            height: 30px;
            font-size: 1.8em;
          "
        />

        <img class="poster" src="/images/poster/BRITISH-HEART-FOUNDATION.png" />
        <button
          type="button"
          v-if="!show"
          @click="onGeneratePdf"
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          GENERATE PDF
        </button>
        <button
          type="button"
          disabled
          v-else
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          <b-spinner large></b-spinner>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      location: "Southam",
      dates: "Monday 27th September, 09:00 - 17:00 \nTuesday 28th September",
      code: "HPTestCode1",
      room: "Main conference room",
      file: [],
      show: false,
      errorImg: false,
    };
  },
  methods: {
    onGeneratePdf() {
      window.open(
        `https://vt6vaxywlb.execute-api.eu-west-2.amazonaws.com/pdf?location=${encodeURIComponent(
          this.location
        )}&dates=${encodeURIComponent(this.dates)}&room=${encodeURIComponent(
          this.room
        )}&code=${encodeURIComponent(this.code)}&type=BHFOHC`,
        "_blank"
      );
    },
  },
};
</script>
<style scoped>
.err {
  width: 100%;
  margin-left: 3px;
  margin-bottom: 0.25rem;
  font-size: 70%;
  color: #dc3545;
}
</style>
