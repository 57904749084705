<template>
  <div>
    <div class="mx-3 float-right">
      <b-link class="text-white" @click="onSignOut" style="font-size: 1.5rem"
        >Log out</b-link
      >
    </div>
    <div class="d-md-flex d-sm-none d-none justify-content-center mt-5 mb-5">
      <div>
        <router-link to="/">
          <img src="/images/logo-lone.png" class="" style="max-height: 69px" />
        </router-link>
      </div>
    </div>

    <div class="d-md-none d-sm-flex d-flex justify-content-center mt-1 mb-2">
      <div class="">
        <div class="my-3">
          <a href="#">
            <img src="/images/logo-mobile.png" class="" style="" />
          </a>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  methods: {
    async onSignOut() {
      console.log("Logout");
      await this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },
};
</script>