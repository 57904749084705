<template>
  <div class="home">
    <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
      <b-form id="preassform" @submit.stop.prevent="onSubmit">
        <div class="d-flex">
          <div class="flex-grow-1">
            <div id="register_code">
              <div class="form-group">
                <b-form-select
                  v-model="poster"
                  class="h-flubox form-control"
                  :options="options"
                ></b-form-select>
              </div>
            </div>
          </div>
          <b-button type="submit" class="btn btn-flubtn btn-flusubmit h-flubox">
            <div class="d-flex justify-content-between align-items-center">
              <div>Submit</div>
              <div>
                <img src="/images/chevron.png" class="ml-2 mr-1" />
              </div>
            </div>
          </b-button>
        </div>
      </b-form>
    </div>
    <div class="container">
      <HealthCheck15 v-if="selected === 'EHA15'" class="mt-3" />
      <HealthCheck15Register
        v-if="selected === 'EHA15-REGISTER'"
        class="mt-3"
      />
      <HealthCheck20 v-if="selected === 'EHA2'" class="mt-3" />
      <HealthCheck20Register
        v-if="selected === 'EHA20-REGISTER'"
        class="mt-3"
      />
      <HealthCheck25PsaThyroid
        v-if="selected === 'EHA25-PSA-THYROID'"
        class="mt-3"
      />
      <HealthCheck25PsaThyroidLiver
        v-if="selected === 'EHA25-PSA-THYROID-LIVER'"
        class="mt-3"
      />
      <HealthCheck25PsaThyroidNR
        v-if="selected === 'EHA25-PSA-THYROID-NR'"
        class="mt-3"
      />
      <HealthCheck25PsaThyroidLiverNR
        v-if="selected === 'EHA25-PSA-THYROID-LIVER-NR'"
        class="mt-3"
      />
      <HealthCheck25Register
        v-if="selected === 'EHA25-REGISTER'"
        class="mt-3"
      />
      <HealthCheck30 v-if="selected === 'EHA30'" class="mt-3" />
      <HealthCheck30Register
        v-if="selected === 'EHA30-REGISTER'"
        class="mt-3"
      />
      <HealthCheck35PsaThyroid
        v-if="selected === 'EHA35-PSA-THYROID'"
        class="mt-3"
      />
      <HealthCheck35PsaThyroidLiver
        v-if="selected === 'EHA35-PSA-THYROID-LIVER'"
        class="mt-3"
      />
      <HealthCheck35PsaThyroidNR
        v-if="selected === 'EHA35-PSA-THYROID-NR'"
        class="mt-3"
      />
      <HealthCheck35PsaThyroidLiverNR
        v-if="selected === 'EHA35-PSA-THYROID-LIVER-NR'"
        class="mt-3"
      />
      <HealthCheck35Register
        v-if="selected === 'EHA35-REGISTER'"
        class="mt-3"
      />

      <HealthCheck408AMDropIn
        v-if="selected === 'EH40-8AM-DROP-IN'"
        class="mt-3"
      />
      <HealthCheck409AMDropIn
        v-if="selected === 'EH40-9AM-DROP-IN'"
        class="mt-3"
      />

      <HealthCheck45 v-if="selected === 'EHA45'" class="mt-3" />
      <HealthCheck45PsaThyroid
        v-if="selected === 'EHA45-PSA-THYROID'"
        class="mt-3"
      />
      <HealthCheck45PsaThyroidLiver
        v-if="selected === 'EHA45-PSA-THYROID-LIVER'"
        class="mt-3"
      />
      <HealthCheck45PsaThyroidNR
        v-if="selected === 'EHA45-PSA-THYROID-NR'"
        class="mt-3"
      />
      <HealthCheck45PsaThyroidLiverNR
        v-if="selected === 'EHA45-PSA-THYROID-LIVER-NR'"
        class="mt-3"
      />
      <HealthCheck45Register
        v-if="selected === 'EHA45-REGISTER'"
        class="mt-3"
      />
      <HealthCheck45PsaThyroidRegister
        v-if="selected === 'EHA45-PSA-THYROID-LIVER-REGISTER'"
        class="mt-3"
      />
      <HealthCheck60 v-if="selected === 'EHA60'" class="mt-3" />
      <HealthCheck60Register
        v-if="selected === 'EHA60-REGISTER'"
        class="mt-3"
      />
      <DigitalFluVoucher
        v-if="selected === 'DIGITAL-FLU-VOUCHER'"
        class="mt-3"
      />
      <DigitalFluVoucherRPS
        v-if="selected === 'DIGITAL-FLU-VOUCHER-RPS'"
        class="mt-3"
      />
      <DigitalFluVoucherReminder
        v-if="selected === 'DIGITAL-FLU-VOUCHER-REMINDER'"
        class="mt-3"
      />
      <DigitalFluVoucherReminderNoCode
        v-if="selected === 'DIGITAL-FLU-VOUCHER-REMINDER-NO-CODE'"
        class="mt-3"
      />
      <AwarenessDaysCalendar
        v-if="selected === 'AWARENESS-DAYS-CALENDAR'"
        class="mt-3"
      />
      <PascalLaunchPoster
        v-if="selected === 'PASCAL-LAUNCH-POSTER'"
        class="mt-3"
      />
      <PascalLaunchFaqs v-if="selected === 'PASCAL-LAUNCH-FAQS'" class="mt-3" />
      <PascalReminder v-if="selected === 'PASCAL-REMINDER'" class="mt-3" />
      <MyWellbeingCheckLaunchPoster
        v-if="selected === 'MYWELLBEINGCHECK-LAUNCH-POSTER'"
        class="mt-3"
      />
      <MyWellbeingCheckLaunchFaqs
        v-if="selected === 'MYWELLBEINGCHECK-LAUNCH-FAQS'"
        class="mt-3"
      />
      <PascalLaunchEmailSignature
        v-if="selected === 'PASCAL-EMAIL-SIGNATURE'"
        class="mt-3"
      />
      <MyWellbeingCheckLaunchEmailSignature
        v-if="selected === 'MYWELLBEINGCHECK-EMAIL-SIGNATURE'"
        class="mt-3"
      />
      <MyWellbeingCheckReminder
        v-if="selected === 'MYWELLBEINGCHECK-REMINDER'"
        class="mt-3"
      />
      <SmoothieBikeEvent
        v-if="selected === 'SMOOTHIE-BIKE-EVENT'"
        class="mt-3"
      />
      <BritishHeartFoundation
        v-if="selected === 'BRITISH-HEART-FOUNDATION'"
        class="mt-3"
      />
      <ControlOfYourWellbeing
        v-if="selected === 'CONTROL-OF-YOUR-WELLBEING'"
        class="mt-3"
      />
      <InsightsLikeNeverBefore
        v-if="selected === 'INSIGHTS-LIKE-NEVER-BEFORE'"
        class="mt-3"
      />
      <WellbeingHotspot v-if="selected === 'WELLBEING-HOTSPOT'" class="mt-3" />
      <OnlineWellbeingAssessment
        v-if="selected === 'ONLINE-WELLBEING-ASSESSMENT'"
        class="mt-3"
      />
      <PositivelyImpactWellbeing
        v-if="selected === 'POSITIVELY-IMPACT-WELLBEING'"
        class="mt-3"
      />
      <PositiveLifestyleChange
        v-if="selected === 'POSITIVE-LIFESTYLE-CHANGE'"
        class="mt-3"
      />
    </div>
  </div>
</template>

<script>
import HealthCheck15 from "../components/HealthCheck15";
import HealthCheck15Register from "../components/HealthCheck15Register";
import HealthCheck20 from "../components/HealthCheck20";
import HealthCheck20Register from "../components/HealthCheck20Register";
import HealthCheck25PsaThyroid from "../components/HealthCheck25PsaThyroid";
import HealthCheck25PsaThyroidLiver from "../components/HealthCheck25PsaThyroidLiver";
import HealthCheck25PsaThyroidNR from "../components/HealthCheck25PsaThyroidNR";
import HealthCheck25PsaThyroidLiverNR from "../components/HealthCheck25PsaThyroidLiverNR";
import HealthCheck25Register from "../components/HealthCheck25Register";
import HealthCheck30 from "../components/HealthCheck30";
import HealthCheck30Register from "../components/HealthCheck30Register";
import HealthCheck35PsaThyroid from "../components/HealthCheck35PsaThyroid";
import HealthCheck35PsaThyroidLiver from "../components/HealthCheck35PsaThyroidLiver";
import HealthCheck35PsaThyroidNR from "../components/HealthCheck35PsaThyroidNR";
import HealthCheck35PsaThyroidLiverNR from "../components/HealthCheck35PsaThyroidLiverNR";
import HealthCheck35Register from "../components/HealthCheck35Register";
import HealthCheck408AMDropIn from "../components/HealthCheck408AMDropIn";
import HealthCheck409AMDropIn from "../components/HealthCheck409AMDropIn";
import HealthCheck45 from "../components/HealthCheck45";
import HealthCheck45PsaThyroid from "../components/HealthCheck45PsaThyroid";
import HealthCheck45PsaThyroidNR from "../components/HealthCheck45PsaThyroidNR";
import HealthCheck45PsaThyroidLiver from "../components/HealthCheck45PsaThyroidLiver";
import HealthCheck45PsaThyroidLiverNR from "../components/HealthCheck45PsaThyroidLiverNR";
import HealthCheck45Register from "../components/HealthCheck45Register";
import HealthCheck45PsaThyroidRegister from "../components/HealthCheck45PsaThyroidRegister";
import HealthCheck60 from "../components/HealthCheck60";
import HealthCheck60Register from "../components/HealthCheck60Register";
import DigitalFluVoucher from "../components/DigitalFluVoucher";
import DigitalFluVoucherRPS from "../components/DigitalFluVoucherRPS"
import DigitalFluVoucherReminder from "../components/DigitalFluVoucherReminder";
import DigitalFluVoucherReminderNoCode from "../components/DigitalFluVoucherReminderNoCode";
import AwarenessDaysCalendar from "../components/AwarenessDaysCalendar";
import PascalLaunchPoster from "../components/PascalLaunchPoster";
import PascalLaunchFaqs from "../components/PascalLaunchFaqs";
import PascalReminder from "../components/PascalReminder";
import MyWellbeingCheckLaunchPoster from "../components/MyWellbeingCheckLaunchPoster";
import MyWellbeingCheckLaunchFaqs from "../components/MyWellbeingCheckLaunchFaqs";
import MyWellbeingCheckReminder from "../components/MyWellbeingCheckReminder";
import PascalLaunchEmailSignature from "../components/PascalLaunchEmailSignature";
import MyWellbeingCheckLaunchEmailSignature from "../components/MyWellbeingCheckLaunchEmailSignature";
import SmoothieBikeEvent from "../components/SmoothieBikeEvent";
import BritishHeartFoundation from "../components/BritishHeartFoundation";
import ControlOfYourWellbeing from "../components/ControlOfYourWellbeing";
import InsightsLikeNeverBefore from "../components/InsightsLikeNeverBefore";
import WellbeingHotspot from "../components/WellbeingHotspot";
import OnlineWellbeingAssessment from "../components/OnlineWellbeingAssessment";
import PositivelyImpactWellbeing from "../components/PositivelyImpactWellbeing";
import PositiveLifestyleChange from "../components/PositiveLifestyleChange";

export default {
  name: "Home",
  components: {
    HealthCheck15,
    HealthCheck15Register,
    HealthCheck20,
    HealthCheck20Register,
    HealthCheck25PsaThyroid,
    HealthCheck25PsaThyroidLiver,
    HealthCheck25PsaThyroidNR,
    HealthCheck25PsaThyroidLiverNR,
    HealthCheck25Register,
    HealthCheck30,
    HealthCheck30Register,
    HealthCheck35PsaThyroid,
    HealthCheck35PsaThyroidLiver,
    HealthCheck35PsaThyroidNR,
    HealthCheck35PsaThyroidLiverNR,
    HealthCheck35Register,
    HealthCheck408AMDropIn,
    HealthCheck409AMDropIn,
    HealthCheck45,
    HealthCheck45PsaThyroid,
    HealthCheck45PsaThyroidNR,
    HealthCheck45PsaThyroidLiver,
    HealthCheck45PsaThyroidLiverNR,
    HealthCheck45Register,
    HealthCheck45PsaThyroidRegister,
    HealthCheck60,
    HealthCheck60Register,
    DigitalFluVoucher,
    DigitalFluVoucherRPS,
    DigitalFluVoucherReminder,
    DigitalFluVoucherReminderNoCode,
    AwarenessDaysCalendar,
    PascalLaunchPoster,
    PascalLaunchFaqs,
    PascalReminder,
    PascalLaunchEmailSignature,
    MyWellbeingCheckLaunchPoster,
    MyWellbeingCheckLaunchFaqs,
    MyWellbeingCheckReminder,
    MyWellbeingCheckLaunchEmailSignature,
    SmoothieBikeEvent,
    BritishHeartFoundation,
    ControlOfYourWellbeing,
    InsightsLikeNeverBefore,
    WellbeingHotspot,
    OnlineWellbeingAssessment,
    PositivelyImpactWellbeing,
    PositiveLifestyleChange,
  },
  data() {
    return {
      poster: null,
      selected: null,
      options: [
        { value: null, text: "Choose poster" },
        { value: "EHA15", text: "15 minute appointment" },
        { value: "EHA15-REGISTER", text: "15 minute register interest" },
        { value: "EHA2", text: "20 minute appointment" },
        {
          value: "EHA20-REGISTER",
          text: "20 minute register interest",
        },
        {
          value: "EHA25-PSA-THYROID",
          text: "25 minute appointment (PSA/Thyroid)",
        },
        {
          value: "EHA25-PSA-THYROID-LIVER",
          text: "25 minute appointment (PSA/Thyroid/Liver)",
        },
        {
          value: "EHA25-PSA-THYROID-NR",
          text: "25 minute appointment NR (PSA/Thyroid)",
        },
        {
          value: "EHA25-PSA-THYROID-LIVER-NR",
          text: "25 minute appointment NR (PSA/Thyroid/Liver)",
        },
        {
          value: "EHA25-REGISTER",
          text: "25 minute register interest",
        },
        { value: "EHA30", text: "30 minute appointment" },
        { value: "EHA30-REGISTER", text: "30 minute register interest" },
        {
          value: "EHA35-PSA-THYROID",
          text: "35 minute appointment (PSA/Thyroid)",
        },
        {
          value: "EHA35-PSA-THYROID-LIVER",
          text: "35 minute appointment (PSA/Thyroid/Liver)",
        },
        {
          value: "EHA35-PSA-THYROID-NR",
          text: "35 minute appointment NR (PSA/Thyroid)",
        },
        {
          value: "EHA35-PSA-THYROID-LIVER-NR",
          text: "35 minute appointment NR (PSA/Thyroid/Liver)",
        },
        {
          value: "EHA35-REGISTER",
          text: "35 minute register interest",
        },
        {
          value: "EH40-8AM-DROP-IN",
          text: "40 minute 8AM blood drop in",
        },
        {
          value: "EH40-9AM-DROP-IN",
          text: "40 minute 9AM blood drop in",
        },
        { value: "EHA45", text: "45 minute appointment" },
        {
          value: "EHA45-PSA-THYROID",
          text: "45 minute appointment (PSA/Thyroid)",
        },
        {
          value: "EHA45-PSA-THYROID-LIVER",
          text: "45 minute appointment (PSA/Thyroid/Liver)",
        },
        {
          value: "EHA45-PSA-THYROID-NR",
          text: "45 minute appointment NR (PSA/Thyroid)",
        },
        {
          value: "EHA45-PSA-THYROID-LIVER-NR",
          text: "45 minute appointment NR (PSA/Thyroid/Liver)",
        },
        {
          value: "EHA45-REGISTER",
          text: "45 minute register interest",
        },
        {
          value: "EHA45-PSA-THYROID-LIVER-REGISTER",
          text: "45 minute (PSA/Thyroid) register interest",
        },
        { value: "EHA60", text: "60 minute appointment" },
        { value: "EHA60-REGISTER", text: "60 minute register interest" },
        { value: "DIGITAL-FLU-VOUCHER", text: "Digital flu voucher" },
        { value: "DIGITAL-FLU-VOUCHER-RPS", text: "Digital flu voucher (RPS)" },
        {
          value: "DIGITAL-FLU-VOUCHER-REMINDER",
          text: "Digital flu voucher reminder",
        },
        {
          value: "DIGITAL-FLU-VOUCHER-REMINDER-NO-CODE",
          text: "Digital flu voucher reminder (No Code)",
        },
        {
          value: "AWARENESS-DAYS-CALENDAR",
          text: "Awareness days calendar",
        },
        {
          value: "PASCAL-LAUNCH-POSTER",
          text: "Pascal launch",
        },
        {
          value: "PASCAL-LAUNCH-FAQS",
          text: "Pascal launch FAQs",
        },
        {
          value: "PASCAL-REMINDER",
          text: "Pascal reminder",
        },
        {
          value: "PASCAL-EMAIL-SIGNATURE",
          text: "Pascal email signature",
        },
        {
          value: "MYWELLBEINGCHECK-LAUNCH-POSTER",
          text: "MyWellBeingCheck launch",
        },
        {
          value: "MYWELLBEINGCHECK-LAUNCH-FAQS",
          text: "MyWellBeingCheck launch FAQs",
        },
        {
          value: "MYWELLBEINGCHECK-REMINDER",
          text: "MyWellBeingCheck Reminder",
        },
        {
          value: "MYWELLBEINGCHECK-EMAIL-SIGNATURE",
          text: "MyWellBeingCheck email signature",
        },
        {
          value: "SMOOTHIE-BIKE-EVENT",
          text: "Smoothie bike event",
        },
        {
          value: "BRITISH-HEART-FOUNDATION",
          text: "British Heart Foundation health checks",
        },
        {
          value: "INSIGHTS-LIKE-NEVER-BEFORE",
          text: "Wellbeing insights like never before",
        },
        {
          value: "WELLBEING-HOTSPOT",
          text: "Find your wellbeing hotspot",
        },
        {
          value: "ONLINE-WELLBEING-ASSESSMENT",
          text: "Online Wellbeing Assessment",
        },
        {
          value: "POSITIVELY-IMPACT-WELLBEING",
          text: "Positively impact your wellbeing",
        },
        {
          value: "POSITIVE-LIFESTYLE-CHANGE",
          text: "Make a positive lifestyle change",
        },
      ],
    };
  },
  methods: {
    onSubmit() {
      this.selected = this.poster;
    },
  },
};
</script>
