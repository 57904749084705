<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <img class="poster" src="/images/poster/AWARENESS-DAYS-CALENDAR.png" />
        <div class="bg-light mt-3 logo-upload-poster-calendar-days">
          <VueFileAgent
            ref="fileRef"
            :multiple="false"
            :deletable="false"
            :meta="false"
            :compact="true"
            :accept="'image/*'"
            :helpText="'Drag an image file here'"
            :errorText="{
              type: 'Please select an image',
            }"
            v-model="file"
          >
            <template v-slot:before-outer> </template>
            <template v-slot:after-inner>
              <span title="after-inner" class="btn btn-link btn-sm btn-block"
                >Select image file</span
              >
            </template>
          </VueFileAgent>
          <div class="err" v-if="errorImg">
            Image not attached, please try again
          </div>
        </div>

        <button
          type="button"
          v-if="!show"
          @click="onGeneratePdf"
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          GENERATE PDF
        </button>
        <button
          type="button"
          disabled
          v-else
          class="btn btn-primary btn-lg btn-block my-3"
          style="width: 1000px"
        >
          <b-spinner large></b-spinner>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
export default {
  data() {
    return {
      location: "Southam",
      dates: "Monday 27th September, 09:00 - 17:00 \nTuesday 28th September, 11:00 - 19:00\nWednesday 29th September, 09:00 - 17:00\nThursday 30th September, 12:00 - 20:00",
      code: "HPTestCode1",
      room: "Main conference room",
      file: [],
      show: false,
      errorImg: false,
    };
  },
  methods: {
    onGeneratePdf() {
      if (!this.file[0]) {
        this.errorImg = true;
        return false;
      }
      this.show = true;
      fetch(
        `https://vt6vaxywlb.execute-api.eu-west-2.amazonaws.com/uploadS3?fileName=${encodeURIComponent(
          this.file[0].file.name
        )}&contentType=${encodeURIComponent(this.file[0].file.type)}`
      )
        // Decode the JSON response
        .then((response) => response.json())
        .then((response) => {
          // Now that we have our pre-signed URL, let's upload our file to this URL:
          fetch(response.url, {
            // We must use the PUT verb because this is a S3.putObject pre-signed URL
            method: "PUT",
            headers: {
              // This must be the same content-type used to create the pre-signed URL
              "Content-Type": this.file[0].file.type,
            },
            // We pass the form field object, `fetch()` will put the file content in there
            body: this.file[0].file,
          }).then((response) => {
            this.show = false;
            window.open(
              `https://vt6vaxywlb.execute-api.eu-west-2.amazonaws.com/pdf?image=${encodeURIComponent(this.file[0].file.name)}&type=AWARENESSDAYS`,
              "_blank"
            );
          });
        });
    },
  },
};
</script>
<style scoped>
.err {
  width: 100%;
  margin-left: 3px;
  margin-bottom: 0.25rem;
  font-size: 70%;
  color: #dc3545;
}
</style>